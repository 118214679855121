<template>
  <div :id="id">
    <div class="row mb-1">
      <div class="col-md-3 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">employee no.</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employeeNo }}</span>
        </div>
      </div>
      <div class="col-md-5 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">name</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.fullName }}</span>
        </div>
      </div>
      <div class="col-md-4 mr-0">
        <div class="row">
          <label class="col-md-8 mr-0 text-left small text-secondary">{{ vacationMaster.vacationYear -1 }} vacation days carried over</label>
          <span class="col-md-2 mr-0 text-right font-weight-bold" style="font-size:90%">{{ vacationMaster.carriedOverDays }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">NMA join date</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ (employeeInfo.sbuStartDate ?  employeeInfo.sbuStartDate : employeeInfo.employmentDate) | toShortDateString }}</span>
        </div>
      </div>
      <div class="col-md-5 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">employee type</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employeeTypeName }}</span>
        </div>
      </div>
      <div class="col-md-4 mr-0">
        <div class="row">
          <label class="col-md-8 mr-0 text-left small text-secondary">annual vacation / travel days due</label>
          <span class="col-md-2 mr-0 text-right font-weight-bold" style="font-size:90%">{{  vacationMaster.vacationDaysDue + vacationMaster.travelDays }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">emp. date</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.employmentDate | toShortDateString }}</span>
        </div>
      </div>
      <div class="col-md-5 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">work schedule</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.workScheduleName }}</span>
        </div>
      </div>
      <div class="col-md-4 mr-0">
        <div class="row">
          <label class="col-md-8 mr-0 text-left small text-secondary">unscheduled vacation days</label>
          <span class="col-md-2 mr-0 text-right font-weight-bold" style="font-size:90%" v-bind:class="{ 'text-danger': unScheduledVacationDaysToDisplay < 0 }">{{ unScheduledVacationDaysToDisplay }}</span>
        </div>
      </div>
    </div>
    <div class="row mb-1">
      <div class="col-md-3 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">vacation year</label>
          <select class="form-control col-md-6 mr-4" v-model="request.vacationYear" required>
            <option v-for="year in additionalData.vacationYears" :value="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-5 mr-0">
        <div class="row">
          <label class="col-md-4 mr-0 text-left small text-secondary">payroll country</label>
          <span class="col-md-8 mr-0 text-left font-weight-bold" style="font-size:90%">{{ employeeInfo.payrollCountry }}</span>
        </div>
      </div>
      <div class="col-md-4 mr-0">
        <div class="row">
          <label class="col-md-8 mr-0 text-left small text-secondary">unscheduled R&amp;R days</label>
          <span class="col-md-2 mr-0 text-right font-weight-bold" style="font-size:90%">{{ vacationMaster.unscheduledRnRDays }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  /*select.form-control:not([size]):not([multiple]) {
    height: 27px;
  }*/
</style>

<script>

  import { mapState } from 'vuex'

  import { toShortDateString } from '@/filters'
  import Enums from '@/utils/enums'

  export default {
    name: 'employee-info-display',
    props: {
      id: {
        id: String,
        required: false,
        default: 'employeeInfoDisplay'
      },
      displayMode: {
        id: Boolean,
        required: false,
        default: false
      },
      employeeInfo: {
        type: Object,
        required: true
      },
      vacationMaster: {
        type: Object,
        required: true
      },
      request: {
        type: Object,
        required: true
      },
      unScheduledVacationDaysToDisplay: {
        type: Number,
        required: false,
      },
      unScheduledRnRDaysToDisplay: {
        type: Number,
        required: false,
      },
      additionalData: {
        type: Object,
        required: false
      }
    },
    //data: () => ({
    //  Enums: Enums
    //}),
    filters: {
      toShortDateString
    },
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
      }),
      payMonths() {
        const self = this
        const today = new Date()
        const currentMonth = today.getMonth() + 1
        const currentDay = today.getDate()
        const payrollCutOff = currentMonth < Enums.Months.November.value ? this.appSettings.vacation.payrollCutOffDayPreNov : this.appSettings.vacation.payrollCutOffDayNovDec
        return Object.keys(Enums.Months)
          .filter(function (key) {
            if (self.vacationMaster.vacationAllowanceRequest.payMonth == Enums.Months[key].value) {
              return true
            }
            if (Enums.Months[key].value == currentMonth) {
              return currentDay <= payrollCutOff
            }
            else {
              return Enums.Months[key].value > currentMonth
            }
          }).reduce((obj, key) => {
            obj[key] = Enums.Months[key];
            return obj;
          }, {});
      },
      disableVacationAllowanceSelection() {
        const today = new Date()
        const currentMonth = today.getMonth() + 1
        const currentDay = today.getDate()
        const payrollCutOff = currentMonth < Enums.Months.November.value ? this.appSettings.vacation.payrollCutOffDayPreNov : this.appSettings.vacation.payrollCutOffDayNovDec
        return (currentMonth == this.vacationMaster.vacationAllowanceRequest.payMonth && currentDay <= payrollCutOff && this.vacationMaster.vacationAllowanceRequest.id) || this.vacationMaster.vacationAllowanceRequest.payMonth < currentMonth
      }
    }
  }
</script>
