<template>
  <div id="rnrRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit r&r entry' : isEditable ? 'r&r entry': 'view r&r entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' request'">
          <transition name="slide-fade">
            <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
          </transition>
          <form id="actionForm" onsubmit="event.preventDefault();">
            <fieldset v-bind:disabled="isActioningRequest">
              <div class="form-group row mt-2">
                <span class="font-weight-bold">comment</span>
                <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                <div class="invalid-feedback">
                  Please enter comments for this action.
                </div>
              </div>
            </fieldset>
          </form>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <app-modal :show="showWarningModal" :static="false" size="medium" title="submit request">
          <fieldset>
            <p class="font-weight-bold">
              I confirm that the information provided is an accurate representation of my existing assignment status and no information has been misrepresented. I understand that there might be consequences if information provided is misrepresented.
            </p>
          </fieldset>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click="showWarningModal = false;">No</button>
            <button class="btn btn-primary d-inline mr-2" @click="request.warningAccepted = true; showWarningModal = false; submitRequest()">Yes</button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForExpatriates" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <employee-info-display v-if="employeeInfo.employeeId" :employeeInfo="employeeInfo" :vacationMaster="vacationMaster" :request="request" :requestCanBeRaisedForEmployee="leaveRequestCanBeRaisedForEmployee" :additionalData="additionalData" :unScheduledVacationDaysToDisplay="unScheduledVacationDaysToDisplay" :unScheduledRnRDaysToDisplay="vacationMaster.unscheduledRnRDays"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <!--<fieldset v-if="!isCompleted" v-bind:disabled="!isEditable || isSavingRequest || isCancellingRequest">
      <div class="row" v-if="leaveRequestCanBeRaisedForEmployee">
        <div class="col">
          <form id="leaveEntryForm" onsubmit="event.preventDefault();" novalidate>
            <RnRRequestEntryForm :request="request" :previouslyScheduledRnRRequests="previouslyScheduledRnRRequests" :employeeInfo="employeeInfo" :isFormDisabled="isSavingRequest || isCancellingRequest" />
            <div class="m-2" v-if="request.id && isEditable">
              <h4>review comment</h4>
              <textarea class="form-control col-md-5 offset-3" rows="4" v-model="comment" required></textarea>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3"></label>
              <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
            </div>
          </form>
          <div class="offset-3 mt-4" v-if="isEditable">
            <button class="btn btn-danger d-inline mr-2" v-bind:class="{ spin: isCancellingRequest }" @click="tryCancelRequest">{{ $route.params.id ? 'Cancel Request' : 'Cancel' }}<span class="spinner"></span></button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="trySubmitRequest">Submit<span class="spinner"></span></button>
          </div>
        </div>
      </div>
    </fieldset>-->
    <div class="row" v-if="leaveRequestCanBeRaisedForEmployee || (!isEditable && request.id)">
      <div class="col">
        <form id="leaveEntryForm" onsubmit="event.preventDefault();" novalidate>
          <RnRRequestEntryForm :request="request" :employeeInfo="employeeInfo" :minimumStartDate="minimumStartDate" :isFormDisabled="disableFields" :isViewPage="isViewPage" />
          <div class="m-2" v-if="request.id && isEditable">
            <h4>review comment</h4>
            <textarea class="form-control col-md-5 offset-3" rows="4" v-model="comment" required v-bind:disabled="disableFields"></textarea>
          </div>
          <div class="form-group row mt-4" v-if="isEditable">
            <span class="col-md-3"></span>
            <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
            <span>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
                  <title>DOA and Back-To-Back is automatically applied</title>
                </path>
              </svg>
            </span>
          </div>
        </form>
        <div class="offset-3 mt-4" v-if="isEditable">
          <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="trySubmitRequest" v-bind:disabled="isSavingRequest || !request.noRepatriationIn90Days">Submit<span class="spinner"></span></button>
        </div>
        <div class="offset-3 mt-4" v-else-if="isReviewPage">
          <button class="btn btn-secondary d-inline mr-2" :style="{visibility: canEditRequest(request) ? 'visible' : 'hidden'}" @click="editRequest(request)">Edit</button>
          <button class="btn btn-primary d-inline mr-2" v-if="canApproveRequest(request)" @click="approveRequest(request)" title="Approve request">Approve</button>
          <button class="btn btn-danger d-inline mr-2" v-if="canCancelRequest(request)" @click="cancelRequest(request)" title="Cancel request">Cancel</button>
          <button class="btn btn-danger d-inline mr-2" v-else-if="canRejectRequest(request)" @click="rejectRequest(request)" title="Reject request">Reject</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canReturnRequest(request)" @click="returnRequest(request)" title="Return request">Return</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canProcessRequest(request)" @click="processRequest(request)" title="process request">Process Payment</button>
        </div>
      </div>
    </div>
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>
  import store from '@/store'
  import { FETCH_PUBLICHOLIDAYS } from '@/store/action-type'

  import Enums from '@/utils/enums'
  import ErrorMessages from '@/utils/errors/messages'
  import { CLOSE_MODAL_EVENT, ALERT_TYPE_ERROR } from '@/utils/constants'


  import differenceInMonths from 'date-fns/differenceInMonths'
  import addMonths from 'date-fns/addMonths'
  import max from 'date-fns/max'

  import { fromServerDate } from '@/utils/date'
  import { employeeService, leaveRequestService, rnrRequestService, vacationMasterService, vacationRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'

  import EmployeeInfoDisplay from '@/components/rnr/EmployeeInfoDisplay'
  import RnRRequestEntryForm from '@/components/rnr/EntryForm'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'

  export default {
    name: 'RnRRequestView',
    components: {
      EmployeeInfoDisplay,
      RnRRequestEntryForm
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      request: {
        id: null,
        vacationYear: null,
        startDate: '',
        endDate: '',
        resumptionDate: '',
        duration: null,
        remarks: '',
        addVacationDays: false,
        vacationDuration: '',
        vacationStartDate: '',
        vacationEndDate: '',
        vacationResumptionDate: '',
        noRepatriationIn90Days: false,
        warningAccepted: false,
        allowance: {
          allowanceDetails: [],
          allowanceDisbursementMode: '',
          payMonth: '',
          exchangeRate: 0,
          status: 0
        },
        intendedApproverId: null,
        intendedApproverName: null,
        status: 0,
        requestActions: [],
        requestType: Enums.RequestType.RnR.value,
        errorAlertType: ALERT_TYPE_ERROR
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        fullName: '',
        sbuStartDate: '',
        locationName: '',
        workScheduleName: '',
        employeeTypeName: '',
        supervisorName: '',
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isExpatriateEmployee: false,
        isResidentExpatriateEmployee: false,
        isTemporalEmployee: false,
        isFieldEmployee: false,
        usdAddressBookDetails: {
          addressBookNumber: '',
          name: ''
        }
      },
      vacationMaster: {
        id: null,
        vacationYear: null,
        vacationDaysDue: 0,
        travelDays: 0,
        vacationAndTravelDays: 0,
        carriedOverDays: 0,
        unscheduledVacationDays: 0,
        unscheduledRnRDays: 0,
        buyOutDays: 0,
        fieldVacationOption: '',
        version: null
      },
      additionalData: {
        vacationYears: [],
        startingUscheduledVacationDays: 0
      },
      comment: null,
      previouslyScheduledRnRRequests: [],
      showWarningModal: false
    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isExpatriateEmployee)
        }
        return []
      },
      unScheduledVacationDaysToDisplay() {
        return this.request.addVacationDays ? this.vacationMaster.unscheduledVacationDays - (this.request.vacationDuration ?? 0) : this.vacationMaster.unscheduledVacationDays
      },
      unScheduledRnRDueDaysToDisplay() {
        return this.request.startDate ? this.vacationMaster.unscheduledRnRDays - (this.request.duration ?? 0) : this.vacationMaster.unscheduledRnRDays
      },
      minimumStartDate() {
        let xMonthsAfterSBUJoin = addMonths(Date.parse(this.employeeInfo.sbuStartDate), this.appSettings.rnR.minimumNumberOfMonthsAfterSBUJoin)
        let minimumStartDate = max([new Date(this.request.vacationYear, 0, 1), xMonthsAfterSBUJoin])
        if (this.previouslyScheduledRnRRequests && this.previouslyScheduledRnRRequests.length) {
          let minimumStartDate = new Date(this.request.vacationYear, 0, 1)
          const lastRnRDate = this.previouslyScheduledRnRRequests[0].endDate
          const threeMonthsAfterLastRnRDate = addMonths(Date.parse(lastRnRDate), this.appSettings.rnR.minimumDifferenceInMonthsBetweenRnR)
          return fromServerDate(max([threeMonthsAfterLastRnRDate, minimumStartDate]))
        }
        return fromServerDate(minimumStartDate)
      },
      leaveRequestCanBeRaisedForEmployee() {
        if (this.isEditable && this.employeeInfo.employeeId) {
          if (this.isEditPage && !this.canEditRequest(this.request)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequestCannotBeModifiedDueToCurrentState, this.request.requestType)
            return false
          }
          // Only resident expatriates can apply to go on R&R
          if (!this.request.id && !this.employeeInfo.isResidentExpatriateEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.InvalidEmployeeType, this.request.requestType)
            return false
          }
          if (!this.employeeInfo.sbuStartDate) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.MissingSBUStartDate, this.request.requestType)
            return false
          }
          const today = new Date()
          const sbuStartDate = new Date(this.employeeInfo.sbuStartDate)
          //if (differenceInMonths(today, sbuStartDate) < this.appSettings.rnR.minimumNumberOfMonthsAfterSBUJoin) {
          //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequiredMinimumSBUStay, this.request.requestType)
          //  return false
          //}
          if (this.minimumStartDate && new Date(this.minimumStartDate).getFullYear() > this.request.vacationYear) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.MaximumNumberOfRequestsInYear, this.request.requestType)
            return false
          }
          //if (this.previouslyScheduledRnRRequests.length > 1 || (sbuStartDate.getFullYear() == today.getFullYear() && sbuStartDate.getMonth() + 1 > Enums.Months.June && this.previouslyScheduledRnRRequests.length > 0)) {
          //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.MaximumNumberOfRequestsInYear, this.request.requestType)
          //  return false
          //}
          if (!this.vacationMaster.id) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
            return false
          }
          if (this.vacationMaster.unscheduledRnRDays < 1 && !this.isEditPage) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoUnscheduledVacationDays, this.requestType)
            return false
          }
          if (!this.employeeInfo.usdAddressBookDetails.addressBookNumber) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.NoUSDAddressBookNUmberForRnR, this.request.requestType)
            return false
          }
          //if (this.request.id && (this.request.status == Enums.RequestStatus.Approved.value) && this.isEditable) {
          //  this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequestCannotBeModifiedDueToCurrentState, this.request.requestType)
          //  return false
          //}
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    watch: {
      'request.vacationYear': {
        immediate: false,
        handler(newValue, oldValue) {
          if (this.employeeInfo.employeeId) {
            this.fetchEmployeeData(this.employeeInfo)
          }
        }
      },
      'request.addVacationDays'(newValue) {
        if (newValue) {
          this.$nextTick(function () {
            NextPage.components.drawers($('.drawers'));
          })
        }
      }
    },
    methods: {
      resetOtherData() {
        this.employeeInfo.sbuStartDate = ''
        this.employeeInfo.isResidentExpatriateEmployee = false
        this.employeeInfo.isTemporalEmployee = false
        this.employeeInfo.usdAddressBookDetails.addressBookNumber = ''
        this.employeeInfo.usdAddressBookDetails.name = ''
        this.request.noRepatriationIn90Days = false
        this.request.warningAccepted = false
        this.request.duration = this.appSettings.rnR.durationForSingleRequest
        this.request.addVacationDays = false
        this.request.vacationStartDate = ''
        this.request.vacationEndDate = ''
        this.request.vacationResumptionDate = ''
        this.request.vacationDuration = null
        this.request.allowance.allowanceDetails.splice(0, this.request.allowance.allowanceDetails.length)
        this.request.allowance.payMonth = ''
        this.request.allowance.allowanceDisbursementMode = ''
        this.request.allowance.status = 0
        this.request.allowance.exchangeRate = 0
        this.previouslyScheduledRnRRequests.splice(0, this.previouslyScheduledRnRRequests.length)
        this.request.version = []
        this.vacationMaster.id = null
        this.vacationMaster.vacationYear = null
        this.vacationMaster.vacationDaysDue = 0
        this.vacationMaster.travelDays = 0
        this.vacationMaster.vacationAndTravelDays = 0
        this.vacationMaster.carriedOverDays = 0
        this.vacationMaster.unscheduledVacationDays = 0
        this.vacationMaster.unscheduledRnRDays = 0
        this.vacationMaster.buyOutDays = 0
        this.vacationMaster.fieldVacationOption = ''
        this.vacationMaster.version = []
        this.additionalData.startingUscheduledVacationDays = 0
      },
      fetchEmployeeData(employee) {
        const self = this
        const tasks = []
        eventBus.$emit(Events.LongOperationStarted, '')
        tasks.push(employeeService.getEmployeeWithWorkType(employee.employeeId))
        tasks.push(rnrRequestService.getAllRequestsFor(employee.employeeId))
        tasks.push(rnrRequestService.getAllowanceDetails(employee.employeeId))
        tasks.push(vacationMasterService.getVacationMasterFor(employee.employeeId, self.request.vacationYear))
        //tasks.push(vacationRequestService.getVacationRequestsFor(employee.employeeId, self.request.vacationYear))
        tasks.push(employeeService.getUSDAddressBookNumber(employee.employeeId))
        Promise.all(tasks).then((results) => {
          self.resetData()
          employee = results[0].data
          let previouslyScheduledRnRRequests = results[1].items
          let allowanceDetails = results[2]
          var vacationMaster = results[3]
          var addressBookDetails = results[4]
          //console.log(addressBookDetails)
          self.setData(employee, null, vacationMaster, previouslyScheduledRnRRequests, allowanceDetails, addressBookDetails)

          //if (vacationMaster) {

          //}
          //else {
          //  self.generateVacationData(employee, null, previouslyScheduledRnRRequests, allowanceDetails)
          //}
        }).catch((error) => {
          self.showErrorMessage(error)
          eventBus.$emit(Events.LongOperationCompleted)
        }).finally(() => {
        })
      },
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.RnR.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          employeeService.getUSDAddressBookNumber(employee.employeeId).then((addressBookDetails) => {
            self.setData(employee, request, vacationMaster, null, null, addressBookDetails)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
          const vacationMaster = request.vacationMaster
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      generateVacationData(employee, request, previouslyScheduledRnRRequests, allowanceDetails) {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, 'Generating vacation data')
        vacationMasterService.createVacationMasterFor({ employeeId: self.employeeInfo.employeeId, vacationYear: self.request.vacationYear }).then((vacationMaster) => {
          self.setVacationMasterData(vacationMaster)
        }).catch((error) => {
          console.log(error)
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      setVacationMasterData(vacationMaster) {
        const self = this
        self.vacationMaster.id = vacationMaster.id
        self.vacationMaster.vacationYear = vacationMaster.vacationYear
        self.vacationMaster.vacationDaysDue = vacationMaster.vacationDaysDue
        self.vacationMaster.travelDays = vacationMaster.travelDays
        self.vacationMaster.vacationAndTravelDays = vacationMaster.vacationAndTravelDays
        self.vacationMaster.carriedOverDays = vacationMaster.carriedOverDays
        self.vacationMaster.unscheduledVacationDays = self.additionalData.startingUscheduledVacationDays = vacationMaster.unscheduledVacationDays
        self.vacationMaster.unscheduledRnRDays = vacationMaster.unscheduledRnRDays
        self.vacationMaster.version = vacationMaster.version
        eventBus.$emit(Events.LongOperationCompleted)
      },
      setData(employee, request, vacationMaster, previouslyScheduledRnRRequests, allowanceDetails, addressBookDetails) {
        const self = this
        self.setEmployeeData(employee)
        self.employeeInfo.employmentDate = employee.employmentDate
        self.employeeInfo.sbuStartDate = employee.sbuStartDate
        self.employeeInfo.payrollCountryId = employee.payrollCountryId
        self.employeeInfo.payrollCountry = employee.payrollCountry
        self.employeeInfo.isResidentExpatriateEmployee = employee.isResidentExpatriateEmployee
        self.employeeInfo.isTemporalEmployee = employee.isTemporalEmployee

        if (request) {
          this.setBaseRequestData(request)
          this.request.allowance.allowanceDisbursementMode = request.allowance.allowanceDisbursementMode
          this.request.allowance.allowancePayType = request.allowance.allowancePayType
          this.request.allowance.payMonth = request.allowance.payMonth
          this.request.allowance.status = request.allowance.status
          this.request.allowance.allowanceDetails = [...request.allowance.allowanceDetails]
          this.request.allowance.exchangeRate = request.allowance.allowanceDetails[0].exchangeRate
          if (previouslyScheduledRnRRequests && previouslyScheduledRnRRequests.length) {
            previouslyScheduledRnRRequests = previouslyScheduledRnRRequests.filter(ps => ps.id != request.id)
          }
          this.request.noRepatriationIn90Days = request.noRepatriationIn90Days
          this.request.warningAccepted = request.warningAccepted
        }
        else {
          self.request.intendedApproverId = employee.supervisorId
          self.request.intendedApproverName = employee.supervisorName
          if (previouslyScheduledRnRRequests && previouslyScheduledRnRRequests.length) {
            for (const pr of previouslyScheduledRnRRequests) {
              self.previouslyScheduledRnRRequests.push(pr)
            }
          }
        }
        if (allowanceDetails && allowanceDetails.length) {
          self.request.allowance.allowanceDetails = [...allowanceDetails]
          this.request.allowance.exchangeRate = allowanceDetails[0].exchangeRate
        }
        if (vacationMaster) {
          this.setVacationMasterData(vacationMaster)
        }
        else {
          if (employee.isResidentExpatriateEmployee) {
            this.generateVacationData()
          }
        }
        if (addressBookDetails) {
          this.employeeInfo.usdAddressBookDetails.addressBookNumber = addressBookDetails.addressBookNumber
          this.employeeInfo.usdAddressBookDetails.name = addressBookDetails.name
        }
        self.pageIsFullyLoaded = true
      },
      trySubmitRequest() {
        const self = this
        self.validateRequest().then((validationResult) => {
          if (validationResult) {
            if (!self.request.warningAccepted) {
              self.showWarningModal = true
            }
            else {
              self.submitRequest()
            }
          }
          //else  {
          //  alert('why else')
          //  //
          //}
        })
      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, '')
      const tasks = []
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(next(vm => {
        vm.resetData()
        if (vm.$route.params.id) {
          vm.fetchExistingData()
        }
        else {
          vm.pageIsFullyLoaded = true
          vm.isCompleted = false
          vm.fetchEmployeeData(vm.currentUser)
        }
      })).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }).finally(() => {
      })
    },
    mounted() {
      const self = this
      const currentYear = new Date().getFullYear()
      self.additionalData.vacationYears.push(currentYear)
      if (new Date().getMonth() >= self.appSettings.vacation.nextYearScheduleStartMonth - 1) {
        self.additionalData.vacationYears.push(currentYear + 1)
      }
      self.request.vacationYear = currentYear
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
