<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false; setEndDate();"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value || request.status == Enums.RequestStatus.Processed.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">start date</label>
          <input type="date" id="startDate" class="form-control col-md-2" v-model="request.startDate" required :min="minimumStartDate" :max="maximumStartDate" />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="col-md-3 invalid-feedback">
            please select a valid date.
          </div>
        </div>
        <!--<div class="row form-group mt-4">
          <label class="col-md-3"></label>
          <input type="checkbox" class="mr-2" v-model="request.addVacationDays" />
          <label class="col-md-7">add additional vacation days date</label>
        </div>-->
        <!--<template v-if="request.addVacationDays">
          <div class="form-group row mt-4" key="vacationDuration">
            <label class="col-md-3">vacation duration</label>
            <input type="number" class="form-control col-md-1" v-model="request.vacationDuration" min="1" :max="appSettings.rnR.maxDurationForVacationWithRnR" required />
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>local holidays</label>
            <input type="number" class="form-input form-control col-md-1" v-model="request.holidays" readonly />
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3">end date</label>
            <input type="date" class="form-control col-md-2" v-model="request.vacationEndDate" readonly required />
            <span> {{ request.vacationEndDate | toLongDateString }}</span>
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3">resumption date</label>
            <input type="date" class="form-control col-md-2" v-model="request.vacationResumptionDate" readonly required />
            <span> {{ request.vacationResumptionDate | toLongDateString }}</span>
          </div>
        </template>-->
        <template>
          <div class="form-group row mt-4">
            <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>local holidays</label>
            <input type="number" class="form-input form-control col-md-1" v-model="request.holidays" readonly />
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3">end date</label>
            <input type="date" class="form-control col-md-2" v-model="request.endDate" readonly required />
            <span> {{ request.endDate | toLongDateString }}</span>
          </div>
          <div class="form-group row mt-4">
            <label class="col-md-3">resumption date</label>
            <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly required />
            <span> {{ request.resumptionDate | toLongDateString }}</span>
          </div>
        </template>
        <!--<div class="drawers" role="tablist">
          <div class="panel-heading" role="tab" aria-selected="false">
            <input type="checkbox" class="col-md-1" v-model="request.addVacationDays" />
            <label class="col-md-7">add additional vacation days date</label>
          </div>
          <div class="panel-content" role="tabpanel" aria-expanded="false">
            <div class="form-group row mt-4">
              <label class="col-md-3">vacation start date</label>
              <input type="date" id="startDate" class="form-control col-md-2" v-model="request.vacationStartDate" readonly required />
              <span> {{ request.vacationStartDate | toLongDateString }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3">vacation duration</label>
              <input type="number" class="form-control col-md-1" v-model="request.vacationDuration" min="1" :max="appSettings.rnR.maxDurationForVacationWithRnR" readonly required />
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3">vacation end date</label>
              <input type="date" class="form-control col-md-2" v-model="request.vacationEndDate" readonly required />
              <span> {{ request.vacationEndDate | toLongDateString }}</span>
            </div>
            <div class="form-group row mt-4">
              <label class="col-md-3">vacation resumption date</label>
              <input type="date" class="form-control col-md-2" v-model="request.vacationResumptionDate" readonly required />
              <span> {{ request.vacationResumptionDate | toLongDateString }}</span>
            </div>
          </div>
        </div>-->
        <!--<transition-group name="slide-fade">
          <template v-if="request.addVacationDays">
            <div class="form-group row mt-4" key="vacationStartDate">
              <label class="col-md-3">vacation start date</label>
              <input type="date" id="startDate" class="form-control col-md-2" v-model="request.vacationStartDate" readonly required />
              <span> {{ request.vacationStartDate | toLongDateString }}</span>
            </div>
            <div class="form-group row mt-4" key="vacationDuration">
              <label class="col-md-3">vacation duration</label>
              <input type="number" class="form-control col-md-1" v-model="request.vacationDuration" min="1" :max="appSettings.rnR.maxDurationForVacationWithRnR" required />
            </div>
            <div class="form-group row mt-4" key="vacationEndDate">
              <label class="col-md-3">vacation end date</label>
              <input type="date" class="form-control col-md-2" v-model="request.vacationEndDate" readonly required />
              <span> {{ request.vacationEndDate | toLongDateString }}</span>
            </div>
            <div class="form-group row mt-4" key="vacationResumptionDate">
              <label class="col-md-3">vacation resumption date</label>
              <input type="date" class="form-control col-md-2" v-model="request.vacationResumptionDate" readonly required />
              <span> {{ request.vacationResumptionDate | toLongDateString }}</span>
            </div>
          </template>
        </transition-group>-->
        <div class="form-group row mt-4">
          <label class="col-md-3">Allowance breakdown for this R&R:</label>
          <table class="col-md-8">
            <tr>
              <th>S/N</th>
              <th>Pay?</th>
              <th>Name</th>
              <th>Relationship</th>
              <th>Age</th>
              <th>Allowance</th>
              <!--<th>Exchange Rate</th>
              <th>Naira (Equiv)</th>-->
            </tr>
            <tr v-for="(allowanceDetail, index) in request.allowance.allowanceDetails" :key="index">
              <td>{{ index+1 }}</td>
              <td><input type="checkbox" v-model="allowanceDetail.pay" v-bind:disabled="(request.allowance.status > Enums.AllowancePaymentStatus.Requested.value) || (allowanceDetail.relationship=='Employee' && allowanceDetail.pay)" /></td>
              <td>{{ allowanceDetail.beneficiaryName }}</td>
              <td>{{ allowanceDetail.relationship }}</td>
              <td>{{ allowanceDetail.beneficiaryAge == -1 ? 'Adult' : allowanceDetail.beneficiaryAge }}</td>
              <td>{{ allowanceDetail.allowance | toNumericDisplay }}</td>
              <!--<td>{{ request.allowance.exchangeRate | toNumericDisplay }}</td>
              <td>{{ (allowanceDetail.allowance * request.allowance.exchangeRate) | toNumericDisplay }}</td>-->
            </tr>
            <tr>
              <th colspan="5" style="text-align: right">Total (To be paid)</th>
              <th>{{ totalUSD | toNumericDisplay }}</th>
              <!--<th colspan="2" style="text-align: right">{{ (totalUSD * request.allowance.exchangeRate) | toNumericDisplay  }}</th>-->
            </tr>
          </table>
          <!--<textarea class="form-control col-md-5" rows="4" v-model="request.remarks"></textarea>-->
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">R&R allowance disbursement mode</label>
          <select class="form-control col-md-3" v-model="request.allowance.allowanceDisbursementMode" v-bind:disabled="request.allowance.status > Enums.AllowancePaymentStatus.Requested.value" required>
            <option value="" disabled>--Select mode of payment--</option>
            <option v-for="allowanceDisbursementMode in allowanceDisbursementModes" :value="allowanceDisbursementMode.value">{{ allowanceDisbursementMode.description }}</option>
          </select>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">preferred month of allowance payment</label>
          <select class="form-control col-md-3" v-model="request.allowance.payMonth" v-bind:disabled="request.allowance.status > Enums.AllowancePaymentStatus.Requested.value" required>
            <option value="" disabled>--Select payment month--</option>
            <option v-for="month in months" :value="month.value">{{ month.description }}</option>
          </select>
        </div>
        <div class="form-group row mt-4" v-if="request.allowance.allowanceDisbursementMode === allAllowanceDisbursementModes.USDAccountDeposit.value">
          <label class="col-md-3">payable to</label>
          <span class="col-md-8 font-weight-bold">{{ employeeInfo.usdAddressBookDetails.addressBookNumber + ' - ' +  employeeInfo.usdAddressBookDetails.name}}</span>
        </div>
        <div class="form-group row mt-4">
          <label for="agreement" class="font-weight-bold text-danger">
            <input type="checkbox" id="agreement" name="agreement" v-model="request.noRepatriationIn90Days" />
            <span>
              I acknowledged that i will Not be repatriating or leaving NMA in 90 days after this R&R and that the above listed DEPENEDENT(s) have been in country (Nigeria) for the last three months
            </span>
          </label>
          <br><br>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }

  .slide-fade-enter-active {
    transition: all .1s ease;
  }

  .slide-fade-leave-active {
    transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  /*table tr th, table tr td {
    font-size: 12px !important;
  }*/
</style>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'
  import { fromServerDate, getLeaveDates, isLeaveStartDateLessThanCurrentDate } from '@/utils/date'

  import isSameDay from 'date-fns/isSameDay'

  import { toNumericDisplay } from '@/filters'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'

  export default {
    name: 'rnr-leave-entry',
    mixins: [LeaveRequestEntryFormMixin],
    filters: {
      toNumericDisplay
    },
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      minimumStartDate: {
        type: String,
        required: true
      },
      isFormDisabled: {
        type: Boolean,
        required: true
      },
      isViewPage: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      months: Enums.Months,
      allAllowanceDisbursementModes: Enums.AllowanceDisbursementModes,
      Enums: Enums
    }),
    computed: {
      ...mapState({
        currentUser: state => state.userModule.currentUser,
        appSettings: state => state.applicationSettingsModule.appSettings
      }),
      allowanceDisbursementModes() {
        if (this.isViewPage) {
          return Enums.AllowanceDisbursementModes
        }
        return [Enums.AllowanceDisbursementModes.USDAccountDeposit]
      },
      maximumStartDate() {
        return fromServerDate(new Date(this.request.vacationYear, 11, 31))
      },
      totalUSD() {
        return this.request.allowance.allowanceDetails.filter(a => a.pay).reduce(function (result, item) {
          return result + item.allowance;
        }, 0);
      }
    },
    watch: {
      'request.addVacationDays': {
        immediate: true,
        handler(newValue) {
          if (!newValue) {
            this.request.vacationDuration = this.request.vacationStartDate = this.request.vacationEndDate = this.request.vacationResumptionDate = null
          }
        }
      },
      'request.vacationDuration'(newValue) {
        if (newValue) {
          this.setEndDate()
        }
      }
    },
    methods: {
      setEndDate() {
        if (this.request.startDate) {
          var dates = getLeaveDates(this.request.startDate, this.request.duration, true, false, this.holidayDates)
          this.request.endDate = dates.endDate
          this.request.resumptionDate = dates.resumptionDate
          if (this.request.addVacationDays && this.request.vacationDuration) {
            this.request.vacationStartDate = dates.resumptionDate
            var dates = getLeaveDates(this.request.vacationStartDate, this.request.vacationDuration, false, false, this.holidayDates)
            this.request.vacationEndDate = dates.endDate
            this.request.vacationResumptionDate = dates.resumptionDate
            this.request.holidays = dates.numberOfHolidays
          }
        }
        else {
          this.request.endDate = null
          this.request.resumptionDate = null
        }
      }
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');
      startDateElement.addEventListener('change', (event) => {
        let selectedDate = new Date(event.target.value)
        var day = new Date(selectedDate).getUTCDay()
        let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
        if (/*[6, 0].includes(day) || */startsOnHoliday) {
          event.preventDefault();
          alert('public holidays not allowed');
          event.target.value = '';
          self.request.startDate = null
          self.request.holidays = null
          self.request.endDate = null
          self.request.resumptionDate = null
          startDateElement.click()
          return
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      })
    }

  }
</script>
